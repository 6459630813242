/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyBv1BbV_mDwNcvrAUcazMqPJPSmaNSUab0",
  "appId": "1:738071735663:web:f33052ebcf74487383cc9a",
  "authDomain": "schooldog-i-lumpkin-ga.firebaseapp.com",
  "measurementId": "G-CSE91C14YL",
  "messagingSenderId": "738071735663",
  "project": "schooldog-i-lumpkin-ga",
  "projectId": "schooldog-i-lumpkin-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-lumpkin-ga.appspot.com"
}
